import styles from "/styles/MobileHome.module.css";
import { useRef } from "react";
import { Text, Box, Image, Stack, Link } from "@chakra-ui/react";
import { motion } from "framer-motion";
import { useInViewport } from "react-in-viewport";

export default function MobileHome() {
  const ref = useRef(null);

  const motionDuration = 1;

  const MotionDiv = motion(Box);
  const MotionImage = motion(Image);
  const { inViewport } = useInViewport(
    ref,
    { rootMargin: "0px" },
    { disconnectOnLeave: true },
    {}
  );

  const transitionSettings = {
    duration: motionDuration,
    delay: 1.0
  };

  return (
    <>
      <Box
        ref={ref}
        width="100%"
        minHeight="234.7222vw"
        bg='url("/images/fk_background.png")'
        bgSize="cover"
        position="relative"
      >
        <Box className={styles.fkMobileHome}>
          <MotionImage
            src="./images/fk/Key1.png"
            animate={{ scale: 1.1 }}
            transition={transitionSettings}
            alt=""
          ></MotionImage>
          <MotionImage
            src="./images/fk/Key2.png"
            animate={{ rotate: 0 }}
            initial={{ rotate: -18 }}
            transition={transitionSettings}
            alt=""
          ></MotionImage>
          <MotionImage
            src="./images/fk/Key3.png"
            initial={{ rotate: 20 }}
            animate={{ rotate: 0 }}
            transition={transitionSettings}
            alt=""
          ></MotionImage>
        </Box>
        <MotionImage
          src="./images/fk/Base.png"
          position="relative"
          margin="0 auto"
          initial={{ top: "75vw" }}
          animate={{ top: "65vw" }}
          transition={transitionSettings}
          alt=""
        ></MotionImage>
        <Box
          padding="32.8vw 1rem 0 1rem"
          position="relative"
          textAlign="center"
          zIndex="10"
        >
          <MotionDiv
            textAlign="left"
            initial={{ lineHeight: "33px" }}
            animate={{ lineHeight: "40px" }}
            transition={transitionSettings}
          >
            <Text
              fontSize="44px"
              color="white"
              fontFamily="Heading Font Name"
              fontWeight="bold"
            >
              A DIFFERENT ENTRY POINT FOR EVERYONE
            </Text>
          </MotionDiv>
          <MotionDiv
            textAlign="left"
            initial={{ lineHeight: "16px" }}
            animate={{ lineHeight: "20px" }}
            transition={transitionSettings}
          >
            <Stack fontSize="16px" color="white" spacing={6}>
              <Text>
                The Founders Keys are available in three separate tiers:
                Relentless, Heroic, and Legendary. Each offers a different level
                of access. Take a look at their utility maps and choose the one
                that’s right for you.
              </Text>
              <Stack color="#0ff3ff" spacing={3}>
                <Link href="/images/fk/legendary-key.png" isExternal>
                  Legendary - Utility Map
                </Link>
                <Link href="/images/fk/heroic-key.png" isExternal>
                  Heroic - Utility Map
                </Link>
                <Link href="/images/fk/relentless-key.png" isExternal>
                  Relentless - Utility Map
                </Link>
              </Stack>
              <Box
                padding="2px 16px"
                borderRadius="13px"
                bgColor="#00F2FF"
                display="inline-flex"
                marginTop="36px"
                fontFamily="Heading Font Name"
                color="#000"
                fontSize="14px"
                maxWidth="160px"
                onClick={() =>
                  window.open(
                    "https://opensea.io/collection/impact-theory-founders-key",
                    "_blank",
                    "noopener,noreferrer"
                  )
                }
              >
                Shop on OpenSea
              </Box>
            </Stack>
          </MotionDiv>
        </Box>
      </Box>
    </>
  );
}
