import styles from "/styles/web3.module.css";
import { Box, Image, Text } from "@chakra-ui/react";
import { useInViewport } from "react-in-viewport";
import { useRef } from "react";
import { motion } from "framer-motion";
export default function MobileWhyWeb3() {
  const ref = useRef(null);
  const MotionBox = motion(Box);
  const MotionImg = motion(Image);
  const { inViewport } = useInViewport(
    ref,
    { rootMargin: "0px" },
    { disconnectOnLeave: true },
    {}
  );

  const renderWidth = width => {
    return (width / 360) * 100 + "vw";
  };

  const transitionSettings = {
    ease: "easeOut",
    duration: 0.5,
    delay: 0.8
  };

  return (
    <Box w="100%" ref={ref} h="100%" position="relative" paddingTop="100px">
      <MotionImg
        position="absolute"
        initial={{ y: "-120%" }}
        animate={{ y: "-95%" }}
        transition={transitionSettings}
        src="./images/whyWeb3/bg.png"
        alt=""
      ></MotionImg>
      <Box>
        <Box className={styles.MobileWeb3imgBox}>
          <MotionImg
            left={renderWidth("44.32")}
            top={renderWidth("69.5")}
            initial={{ x: "0" }}
            animate={{ x: "-3.63889vw" }}
            width={renderWidth("160.13")}
            transition={transitionSettings}
            src="./images/whyWeb3/Annville.png"
            alt=""
          ></MotionImg>
          <MotionImg
            left={renderWidth("63.59")}
            top={renderWidth("250.8")}
            initial={{ x: "0" }}
            animate={{ x: "-5.5556vw" }}
            width={renderWidth("43.39")}
            transition={transitionSettings}
            src="./images/whyWeb3/BOTITA.png"
            alt=""
          ></MotionImg>
          <MotionImg
            left={renderWidth("235.21")}
            top={renderWidth("61.23")}
            initial={{ x: "0" }}
            animate={{ x: "0.89vw" }}
            width={renderWidth("99.25")}
            transition={transitionSettings}
            src="./images/whyWeb3/Byte.png"
            alt=""
          ></MotionImg>
          <MotionImg
            left={renderWidth("76.88")}
            top={renderWidth("248.75")}
            initial={{ x: "0" }}
            animate={{ x: "-2.77778vw" }}
            transition={transitionSettings}
            width={renderWidth("76.28")}
            src="./images/whyWeb3/ElfRGBA.png"
            alt=""
          ></MotionImg>
          <MotionImg
            left={renderWidth("320.9")}
            top={renderWidth("98.95")}
            width={renderWidth("69.1")}
            initial={{ x: "0" }}
            animate={{ x: "1.447vw" }}
            transition={transitionSettings}
            src="./images/whyWeb3/Flexx.png"
            alt=""
          ></MotionImg>
          <Image
            left={renderWidth("143.24")}
            top={renderWidth("66.19")}
            width={renderWidth("110.83")}
            src="./images/whyWeb3/Gamer.png"
            alt=""
          />
          <MotionImg
            left={renderWidth("256.12")}
            top={renderWidth("259.21")}
            initial={{ x: "0" }}
            animate={{ x: "2.777777vw" }}
            transition={transitionSettings}
            width={renderWidth("58.6")}
            src="./images/whyWeb3/Girl.png"
            alt=""
          ></MotionImg>
          <MotionImg
            left={renderWidth("62.18")}
            top={renderWidth("76.77")}
            initial={{ x: "0" }}
            animate={{ x: "-6.442vw" }}
            transition={transitionSettings}
            width={renderWidth("68.82")}
            src="./images/whyWeb3/Lullaby.png"
            alt=""
          ></MotionImg>
          <MotionImg
            left="0"
            top={renderWidth("82.07")}
            initial={{ x: "0" }}
            animate={{ x: "-9.22vw" }}
            width={renderWidth("111.15")}
            transition={transitionSettings}
            src="./images/whyWeb3/Mach.png"
            alt=""
          ></MotionImg>
          <MotionImg
            left={renderWidth("176.61")}
            top={renderWidth("238.31")}
            initial={{ y: "0" }}
            animate={{ y: "-20px" }}
            transition={transitionSettings}
            width={renderWidth("111.72")}
            src="./images/whyWeb3/SantaRGBA.png"
            alt=""
          ></MotionImg>
          <MotionImg
            left={renderWidth("95.78")}
            top={renderWidth("221.51")}
            initial={{ y: "0" }}
            animate={{ y: "-20px" }}
            transition={transitionSettings}
            width={renderWidth("127.02")}
            src="./images/whyWeb3/SnowBo_no_background.png"
            alt=""
          ></MotionImg>
          <MotionImg
            left={renderWidth("291.13")}
            top={renderWidth("76.11")}
            width={renderWidth("58.23")}
            initial={{ y: "0" }}
            animate={{ x: "2.61vw" }}
            transition={transitionSettings}
            src="./images/whyWeb3/Zone.png"
            alt=""
          ></MotionImg>
          <MotionImg
            left={renderWidth("301.88")}
            top={renderWidth("260.35")}
            initial={{ y: "0" }}
            animate={{ x: "5.5555555555vw" }}
            transition={transitionSettings}
            width={renderWidth("29.58")}
            src="./images/whyWeb3/Candy.cane.png"
            alt=""
          ></MotionImg>
        </Box>

        <MotionBox
          marginTop="72vw"
          padding="0 1rem"
          initial={{ x: "-100%" }}
          animate={{ x: "0" }}
          transition={transitionSettings}
        >
          <Text
            color="black"
            fontFamily="Heading Font Name"
            fontSize="42px"
            lineHeight="61px"
          >
            WHY WEB3?
          </Text>
          <Text color="black" fontSize="15px" lineHeight="19px">
            Blockchain technology has created the opportunity for a whole new
            type of “reactive” entertainment - and Project Kyzen is taking full
            advantage! NFTs aren’t just art or in-game items, they pack a
            powerful punch with cross-ecosystem utility. With Web3 you can carry
            your passions in your wallet wherever you go and our systems will
            respond accordingly - delivering a tailored experience just for you.
          </Text>
          <Text color="black" fontSize="15px" lineHeight="19px" marginTop={3}>
            Depending on the NFTs you have in your wallet, our in-game and
            out-of-game experiences react - opening some doors and closing
            others based on the things you love most. This was impossible in
            Web2 and will only get better over time in Web3. From airdrops and
            special in-game levels and experiences, to perks and exclusive
            access, your suite of Impact Theory NFTs customize your
            entertainment journey across our entire ecosystem.
          </Text>
        </MotionBox>
      </Box>
    </Box>
  );
}
