import styles from "/styles/Home.module.css";
import { useRef, useState } from "react";
import {
  Box,
  Image,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Stack,
  Link,
  useMediaQuery,
} from "@chakra-ui/react";
import { motion } from "framer-motion";
import { useInViewport } from "react-in-viewport";

export default function HomeSection({ isOpen }) {
  const ref = useRef(null);
  const [open, setOpen] = useState(false);
  const [type, setType] = useState(0);
  const motionDuration = 0.5;
  const [isMaxThan1024] = useMediaQuery("(max-width: 1024px)");
  const [isMaxThan1920] = useMediaQuery("(min-width: 1920px)");

  const MotionDiv = motion(Box);
  const MotionImage = motion(Image);
  const { inViewport } = useInViewport(
    ref,
    { rootMargin: "0px" },
    { disconnectOnLeave: true },
    {}
  );

  const transitionSettings = {
    // ease: "easeOut",
    duration: motionDuration,
    delay: 1.0,
  };

  return (
    <>
      <Box
        ref={ref}
        width="100%"
        minHeight="74.01vw"
        bg='url("/images/fk_background.png")'
        bgSize="cover"
        position="relative"
      >
        <Box className={styles.fkHome}>
          <MotionImage
            src="./images/fk/Key1.png"
            animate={{ scale: 1.1 }}
            width={isMaxThan1920 ? "600px" : "400px"}
            zIndex="0"
            transition={transitionSettings}
            alt=""
          ></MotionImage>
          <MotionImage
            width={isMaxThan1920 ? "600px" : "400px"}
            zIndex="0"
            src="./images/fk/Key2.png"
            initial={{ rotate: -18 }}
            animate={{ rotate: 0 }}
            transition={transitionSettings}
            alt=""
          ></MotionImage>
          <MotionImage
            src="./images/fk/Key3.png"
            width={isMaxThan1920 ? "600px" : "400px"}
            zIndex="0"
            initial={{ rotate: 18 }}
            animate={{ rotate: 0 }}
            transition={transitionSettings}
            alt=""
          ></MotionImage>
        </Box>

        {/* overlay */}
        <Box className={styles.fkHome}>
          <Box
            cursor="pointer"
            width="38%"
            height="310px"
            // backgroundColor="lime"
            // opacity="0.5"
            zIndex="1000"
            top="15vw"
            left="32vw"
            position="absolute"
            onClick={() => {
              if (isOpen === "open") {
                setType(1);
                setOpen(true);
              }
            }}
          ></Box>
          <Box
            cursor="pointer"
            width="38%"
            height="300px"
            // backgroundColor="pink"
            // opacity="0.5"
            zIndex="500"
            left="44vw"
            top="-5vw"
            position="absolute"
            margin="150px"
            onClick={() => {
              if (isOpen === "open") {
                setType(3);
                setOpen(true);
              }
            }}
          ></Box>
          <Box
            cursor="pointer"
            width="38%"
            height="300px"
            // backgroundColor="purple"
            // opacity="0.5"
            left="-1vw"
            top="-5vw"
            zIndex="500"
            position="absolute"
            margin="150px"
            onClick={() => {
              if (isOpen === "open") {
                setType(2);
                setOpen(true);
              }
            }}
          ></Box>
        </Box>

        <MotionImage
          src="./images/fk/home-base.png"
          margin="0 auto"
          sx={{
            objectFit: "cover",
            objectPosition: "top",
          }}
          width={isMaxThan1920 ? "1176px" : "500px"}
          initial={{ y: isMaxThan1920 ? "27vw" : "25vw" }}
          animate={{ y: isMaxThan1920 ? "30vw" : "28vw" }}
          transition={transitionSettings}
          alt=""
        ></MotionImage>
        <Box
          className={styles.differentEntryText}
          width="470px"
          position="absolute"
          left="104px"
          bottom={isMaxThan1920 ? "430px" : "260px"}
          color="#fff"
        >
          <MotionDiv
            initial={{ lineHeight: "46px" }}
            animate={{ lineHeight: "61px" }}
            transition={transitionSettings}
          >
            <Text
              fontSize="50px"
              color="white"
              fontWeight="bold"
              className={styles.differentEntryHed}
            >
              A DIFFERENT
              <br />
              ENTRY POINT
              <br />
              FOR EVERYONE
            </Text>
          </MotionDiv>
          <MotionDiv
            initial={{ lineHeight: "20px" }}
            animate={{ lineHeight: "25px" }}
            transition={transitionSettings}
          >
            <Stack fontSize="20px" color="white" spacing={6}>
              <Text>
                The Founders Keys are available in three separate tiers:
                Relentless, Heroic, and Legendary. Each offers a different level
                of access. Take a look at their utility maps and choose the one
                that’s right for you.
              </Text>
              <Stack color="#0ff3ff" spacing={1}>
                <Link
                  onClick={() => {
                    if (isOpen === "open") {
                      setType(1);
                      setOpen(true);
                    }
                  }}
                >
                  Legendary - Utility Map
                </Link>
                <Link
                  onClick={() => {
                    if (isOpen === "open") {
                      setType(3);
                      setOpen(true);
                    }
                  }}
                >
                  Heroic - Utility Map
                </Link>
                <Link
                  onClick={() => {
                    if (isOpen === "open") {
                      setType(2);
                      setOpen(true);
                    }
                  }}
                >
                  Relentless - Utility Map
                </Link>
              </Stack>
              <Box
                onClick={() =>
                  window.open(
                    "https://opensea.io/collection/impact-theory-founders-key",
                    "_blank",
                    "noopener,noreferrer"
                  )
                }
                className={styles.learnMore}
              >
                Shop on OpenSea
              </Box>
            </Stack>
          </MotionDiv>
        </Box>
        <Modal isOpen={open}>
          <ModalOverlay />
          <ModalContent maxW="80vw" opacity="0.95">
            <ModalBody padding="0">
              <Image
                width="80vw"
                cursor="pointer"
                alt=""
                onClick={() => {
                  setType(0);
                  setOpen(false);
                }}
                src={
                  type === 3
                    ? "./images/fk/heroic-key.png"
                    : type === 2
                    ? "./images/fk/relentless-key.png"
                    : "./images/fk/legendary-key.png"
                }
              ></Image>
            </ModalBody>
          </ModalContent>
        </Modal>
      </Box>
    </>
  );
}
