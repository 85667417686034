import styles from "/styles/Home.module.css";
import {
  Text,
  Box,
  Grid,
  GridItem,
  Stack,
  Image,
  Center,
} from "@chakra-ui/react";
import { useInViewport } from "react-in-viewport";
import { useRef } from "react";
import { motion } from "framer-motion";

export default function MobileHouseOfIdeas() {
  const ref = useRef(null);
  const { inViewport } = useInViewport(
    ref,
    { rootMargin: "-50px" },
    { disconnectOnLeave: false },
    {}
  );

  const motionDuration = 1;

  const MotionImage = motion(Image);
  const MotionDiv = motion(Box);

  const transitionSettings = {
    duration: motionDuration,
    delay: 0.8
  };

  return (
    <Box w="100%" color="#000000">
      <Box ref={ref} marginTop="100px">
        <Box display="flex" direction="row">
          <Grid templateColumns="repeat(7, 1fr)">
            <GridItem>
              <MotionImage
                src="./images/story-neon-future.png"
                alt="Neon Future"
                initial={{ postion: "relative", top: "-49px" }}
                animate={{ postion: "relative", top: "0" }}
                transition={transitionSettings}
                position="relative"
                top="-49px"
              />
            </GridItem>
            <GridItem>
              <Image
                src="./images/story-wish-academy.png"
                alt="Wish Academy"
                position="relative"
                top="0"
              />
            </GridItem>
            <GridItem>
              <MotionImage
                src="./images/story-cutting-black.png"
                alt="Cutting Black"
                position="relative"
                top="-95px"
                initial={{ postion: "relative", top: "-95px" }}
                animate={{ postion: "relative", top: "0" }}
                transition={transitionSettings}
              />
            </GridItem>
            <GridItem>
              <MotionImage
                objectFit="cover"
                src="./images/story-coyotes.png"
                alt="Coyotes of the Air Gap"
                position="relative"
                top="-33px"
                initial={{ postion: "relative", top: "-33px" }}
                animate={{ postion: "relative", top: "0" }}
                transition={transitionSettings}
              />
            </GridItem>
            <GridItem>
              <MotionImage
                objectFit="cover"
                src="./images/story-all-systems.png"
                alt="All Systems Go"
                position="relative"
                top="-66px"
                initial={{ postion: "relative", top: "-66px" }}
                animate={{ postion: "relative", top: "0" }}
                transition={transitionSettings}
              />
            </GridItem>
            <GridItem>
              <MotionImage
                objectFit="cover"
                src="./images/story-burnland.png"
                alt="Burnland"
                position="relative"
                top="-95px"
                initial={{ postion: "relative", top: "-95px" }}
                animate={{ postion: "relative", top: "0" }}
                transition={transitionSettings}
              />
            </GridItem>
            <GridItem>
              <MotionImage
                objectFit="cover"
                src="./images/story-hexagon.png"
                alt="Hexagon"
                position="relative"
                top="-20px"
                initial={{ postion: "relative", top: "-20px" }}
                animate={{ postion: "relative", top: "0" }}
                transition={transitionSettings}
              />
            </GridItem>
          </Grid>
        </Box>
        <Box padding="0 12px" marginTop="27px">
          <Box>
            <Text
              fontSize="1.8rem"
              lineHeight="1.6rem"
              fontFamily="Heading Font Name"
            >
              WE ARE THE NEW
            </Text>
            <Text
              fontSize="1.6rem"
              lineHeight="1.4rem"
              fontFamily="Heading Font Name"
            >
              HOUSE OF IDEAS
            </Text>
          </Box>
          <Box fontSize="15px" lineHeight="19px">
            Our mission is to introduce people to empowering ideas at scale
            through story. In addition to Project Kyzen and Merry Modz, we
            currently have an entire slate of stories in development. There are
            several different ways that our projects will take to come to life,
            but the most common path will be starting as a comic book. This will
            allow us to explore the world and story as we build an audience. Two
            of our projects have already found success in this way (Neon Future
            and Hexagon). Both projects spent their entire run in the top 10 of
            their respective categories on Webtoon - garnering more than a
            million views collectively.
          </Box>
        </Box>

        {/* </ScaleFade> */}
      </Box>
    </Box>
  );
}
