import styles from "../../styles/MobileCarousel.module.css";
import { Box, Image, Text } from "@chakra-ui/react";
import { motion } from "framer-motion";
import { useState, useRef, useEffect } from "react";

export default function MobileCarousel({ scrollToRef, scrollToRefOpenSea }) {
  const ref = useRef(null);

  const MotionImage = motion(Image);
  const MotionBox = motion(Box);
  const motionDuration = 1;
  const [index, setIndex] = useState(0);
  const [timerID, setTimerID] = useState(null);
  const [counter, setCounter] = useState(0);

  useEffect(() => {
    if (counter > 0) {
      const timer = setTimeout(() => {
        setCounter(counter - 1);
      }, 1000);
      setTimerID(timer);
    } else if (counter === 0) {
      if (index === 1) {
        setIndex(2);
        setCounter(20);
      } else if (index === 2) {
        setIndex(0);
      }
    }
    return () => {
      setTimerID(null);
    };
  }, [counter, index]);

  useEffect(() => {
    return () => {
      if (timerID) {
        clearTimeout(timerID);
      }
    };
  }, [timerID]);
  return (
    <Box pos="relative" height="216.41vw">
      {index === 0 && (
        <Box bgColor="#000" height="216.41vw">
          <video
            width="100%"
            autoPlay
            muted
            playsInline
            ref={ref}
            onEnded={() => {
              setIndex(1);
              setCounter(20);
            }}
          >
            <source src="/images/main-video.mp4" type="video/mp4" />
            Your browser does not support the video tag
          </video>
          <MotionBox
            zIndex="1"
            padding="0 1rem"
            top="425px"
            position="absolute"
            initial={{ left: counter === 20 ? "-100%" : "5.78125vw" }}
            animate={{ left: "5.78125vw" }}
            transition={{ duration: motionDuration }}
          >
            <MotionImage
              src="./images/carousel/carousel-1-title.svg"
              alt=""
            ></MotionImage>
            <Text
              marginTop="12px"
              fontSize="24px"
              color="white"
              fontFamily="Heading Font Name"
              lineHeight="26px"
              fontWeight="700"
            >
              EMPOWERMENT THROUGH
            </Text>
            <Text
              fontSize="36px"
              color="white"
              lineHeight="35px"
              fontFamily="Heading Font Name"
              fontWeight="bold"
              marginBottom="12px"
            >
              NFTS AND STORY
            </Text>
            <Text
              fontSize="16px"
              color="white"
              lineHeight="17px"
              marginBottom="12px"
            >
              The best way to improve someone’s life is to introduce them to
              useful ideas. And the best way to introduce useful ideas is
              through storytelling.
            </Text>
            <Text fontSize="16px" color="white" lineHeight="17px">
              Grab a Key and unlock the future of storytelling.
            </Text>
            <Box
              onClick={() => {
                scrollToRef.current.scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                });
              }}
              className={styles.learnMore}
            >
              LEARN MORE
            </Box>
          </MotionBox>
        </Box>
      )}
      {index === 1 && (
        <Box
          width="100%"
          height="216.41vw"
          bg='url("/images/carousel/BG.png")'
          bgSize="cover"
          position="relative"
        >
          <Box className={styles.carouselFK}>
            <MotionImage
              src="./images/carousel/Base.png"
              top="80vw"
              zIndex="2"
              transition={{ ease: "easeIn", duration: motionDuration }}
              alt=""
            ></MotionImage>
            <MotionImage
              width="50.1333vw"
              src="./images/carousel/Layer_3.png"
              top="60.8564vw"
              initial={{
                left: counter === 20 ? "27.231vw" : "-3vw",
                rotate: counter === 20 ? "0" : "-10",
              }}
              animate={{ left: "-3vw", rotate: -10 }}
              transition={{ duration: motionDuration }}
              alt=""
            ></MotionImage>
            <MotionImage
              width="50.06vw"
              src="./images/carousel/Layer_2.png"
              top="60.8564vw"
              initial={{
                left: counter === 20 ? "27.231vw" : "52.9385vw",
                rotate: counter === 20 ? "0" : "10",
              }}
              animate={{ left: "52.9385vw", rotate: 10 }}
              transition={{ duration: motionDuration }}
              alt=""
            ></MotionImage>
            <MotionImage
              width="50.13vw"
              src="./images/carousel/Layer_1.png"
              initial={{
                left: counter === 20 ? "27.231vw" : "27.55vw",
                top: counter === 20 ? "60.8564vw" : "52vw",
              }}
              animate={{ left: "27.55vw", top: "52vw" }}
              transition={{ duration: motionDuration }}
              alt=""
            ></MotionImage>
          </Box>
          <Image
            position="relative"
            top="65vw"
            transform="scale(1.5)"
            src="./images/carousel/Dust.png"
            alt=""
          ></Image>
          <Box
            width="100%"
            textAlign="center"
            padding="0 1rem"
            position="absolute"
            zIndex="3"
            top="100vw"
          >
            <MotionBox
              position="relative"
              initial={{ left: counter === 20 ? "-100%" : "0" }}
              animate={{ left: "0" }}
              transition={{ duration: motionDuration }}
            >
              <Text
                fontSize="36px"
                color="white"
                lineHeight="38px"
                fontWeight="bold"
              >
                GRAB A KEY AND
              </Text>
              <Text
                fontSize="57px"
                color="white"
                lineHeight="51px"
                fontWeight="bold"
              >
                UNLOCK
              </Text>
              <Text
                fontSize="51px"
                color="white"
                lineHeight="68px"
                fontWeight="bold"
              >
                THE FUTURE
              </Text>
            </MotionBox>
            <MotionBox
              position="relative"
              initial={{ left: counter === 20 ? "-100%" : "0" }}
              animate={{ left: "0" }}
              transition={{ duration: motionDuration }}
            >
              <Text fontSize="20px" lineHeight="25px" color="white">
                We’re the new house of ideas and the Founders Keys deepen your
                relationship to all of our initiatives. Get special access to
                our video content, our University, our narrative content,
                everything we’re doing in Web3, and the upcoming game world
                Project Kyzen that ties our entire creative universe together in
                one empowering place.
              </Text>
              <Box
                padding="2px 16px"
                borderRadius="13px"
                bgColor="#00F2FF"
                display="inline-flex"
                marginTop="12px"
                fontFamily="Heading Font Name"
                color="#000"
                fontSize="14px"
                onClick={() => {
                  scrollToRefOpenSea.current.scrollIntoView({
                    behavior: "smooth",
                    block: "start",
                  });
                }}
              >
                LEARN MORE
              </Box>
            </MotionBox>
          </Box>
        </Box>
      )}
      {index === 2 && (
        <MotionBox
          height="216.41vw"
          bg='transparent url("/images/carousel-3-bg.png") 50% 0% no-repeat padding-box'
          bgSize="cover"
          width="100%"
          paddingTop="34.87vw"
        >
          <MotionBox
            padding="0 1rem"
            position="relative"
            textAlign="center"
            zIndex="9"
            initial={{ left: counter === 20 ? "-100%" : "0" }}
            animate={{ left: "0" }}
            transition={{ duration: motionDuration }}
          >
            <Text
              fontSize="36px"
              color="#000"
              lineHeight="38px"
              fontWeight="bold"
            >
              PROJECT
            </Text>
            <Text
              fontSize="57px"
              color="#000"
              lineHeight="51px"
              fontWeight="bold"
            >
              KYZEN
            </Text>
            <Text
              fontSize="24px"
              color="#000"
              lineHeight="28px"
              fontWeight="bold"
            >
              A NEW FORM OF ENTERTAINMENT
            </Text>
          </MotionBox>
          <MotionBox
            position="relative"
            padding="1rem 1rem"
            zIndex="9"
            textAlign="center"
            initial={{ left: counter === 20 ? "-100%" : "0" }}
            animate={{ left: "0" }}
            transition={{ duration: motionDuration }}
          >
            <Text fontSize="20px" lineHeight="25px" color="#000">
              Using NFTs as “signaling molecules” that tailor in-game
              experiences based on what a player holds in their wallet, Impact
              Theory is proving NFTs are a revolution in entertainment.
            </Text>
            <Box
              padding="2px 16px"
              borderRadius="13px"
              bgColor="#00F2FF"
              display="inline-flex"
              marginTop="12px"
              fontFamily="Heading Font Name"
              color="#000"
              fontSize="14px"
              onClick={() => {
                window.open("https://projectkyzen.io");
              }}
            >
              LEARN MORE
            </Box>
          </MotionBox>
          <Box position="absolute" left="0" top="0">
            <MotionImage
              src="./images/carousel/mobile-carousel-3-bg.svg"
              alt=""
              width="100vw"
              position="relative"
              transition={{ ease: "easeIn", duration: motionDuration }}
            />
            <MotionImage
              src="./images/carousel/mobile-carousel-3.png"
              alt=""
              position="relative"
              bottom="0"
              initial={{ y: counter === 20 ? "0" : "-110px" }}
              animate={{ y: "-110px" }}
              transition={{ ease: "easeIn", duration: motionDuration }}
            />
          </Box>
        </MotionBox>
      )}

      <Box className={styles.carouselIndex}>
        <motion.img
          whileHover={{ rotate: 90 }}
          src={`./images/hexagon_home_${index === 0 ? "on" : "off"}.png`}
          onClick={() => {
            setCounter(0);
            if (timerID) {
              clearTimeout(timerID);
              setTimerID(null);
            }
            setIndex(0);
          }}
          alt=""
        />
        <motion.img
          whileHover={{ rotate: 90 }}
          src={`./images/hexagon_fk_${index === 1 ? "on" : "off"}.png`}
          onClick={() => {
            setIndex(1);
            setCounter(20);
          }}
          alt=""
        />
        <motion.img
          whileHover={{ rotate: 90 }}
          src={`./images/hexagon_kyzen_${index === 2 ? "on" : "off"}.png`}
          onClick={() => {
            setIndex(2);
            setCounter(20);
          }}
          alt=""
        />
        <motion.img
          src={`./images/carousel/trumpet.svg`}
          onClick={() => {
            if (ref) {
              ref.current.muted = !ref.current.muted;
            }
          }}
          alt=""
        />
      </Box>
    </Box>
  );
}
