import styles from "../../styles/Home.module.css";
import { Box, Image, Text, useMediaQuery } from "@chakra-ui/react";
import { motion } from "framer-motion";
import { useState, useRef, useEffect } from "react";

export default function CarouselSection({ scrollToRef, scrollToRefOpenSea }) {
  const ref = useRef(null);
  const MotionImage = motion(Image);
  const MotionBox = motion(Box);
  const motionDuration = 1;
  const [index, setIndex] = useState(0);
  const [timerID, setTimerID] = useState(null);
  const [counter, setCounter] = useState(0);
  const [isMaxThan1024] = useMediaQuery("(max-width: 1024px)");

  useEffect(() => {
    if (counter > 0) {
      const timer = setTimeout(() => {
        setCounter(counter - 1);
      }, 1000);
      setTimerID(timer);
    } else if (counter === 0) {
      if (index === 1) {
        setIndex(2);
        setCounter(20);
      } else if (index === 2) {
        setIndex(0);
      }
    }
    return () => {
      setTimerID(null);
    };
  }, [counter, index]);

  useEffect(() => {
    return () => {
      if (timerID) {
        clearTimeout(timerID);
      }
    };
  }, [timerID]);
  useEffect(() => {
    if (index === 0) {
      setCounter(0);
      if (timerID) {
        clearTimeout(timerID);
      }
      setTimerID(null);
    }
  }, [index, timerID]);
  return (
    <Box pos="relative" height="54.0104vw">
      {index === 0 && (
        <Box>
          <video
            width="100%"
            autoPlay
            ref={ref}
            muted
            playsInline
            onEnded={() => {
              setIndex(1);
              setCounter(20);
            }}
          >
            <source src="/images/main-video.mp4" type="video/mp4" />
            Your browser does not support the video tag
          </video>
          <MotionBox
            zIndex="1"
            width="480px"
            top={isMaxThan1024 ? "12vw" : "25vw"}
            position="absolute"
            initial={{ left: "-100%" }}
            animate={{ left: "5.78125vw" }}
            transition={{ duration: motionDuration }}
          >
            <MotionImage
              width="257px"
              src="./images/carousel/carousel-1-title.svg"
              alt=""
            ></MotionImage>
            <Box
              fontSize="34px"
              color="white"
              marginTop="24px"
              lineHeight="34px"
              fontWeight="700"
            >
              EMPOWERMENT
            </Box>
            <Box
              fontSize="26px"
              color="white"
              lineHeight="34px"
              fontWeight="bold"
              marginBottom="12px"
            >
              THROUGH NFTS & STORY
            </Box>
            <Box
              fontSize="20px"
              color="white"
              lineHeight="25px"
              marginBottom="12px"
            >
              The best way to improve someone’s life is to introduce them to
              useful ideas. And the best way to introduce useful ideas is
              through storytelling.
            </Box>
            <Box fontSize="20px" color="white" lineHeight="25px">
              Grab a Key and unlock the future of storytelling.
            </Box>
            <Box
              onClick={() => {
                scrollToRef.current.scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                });
              }}
              className={styles.learnMore}
            >
              LEARN MORE
            </Box>
          </MotionBox>
        </Box>
      )}
      {index === 1 && (
        <Box
          width="100%"
          minHeight="74.01vw"
          bg='url("/images/fk_background.png")'
          bgSize="cover"
          position="relative"
        >
          <Box className={styles.carouselFK}>
            <MotionImage
              src="./images/carousel/Base.png"
              left="10vw"
              width="80vw"
              initial={{ y: counter === 20 ? "36vw" : "33vw" }}
              animate={{ y: "33vw" }}
              transition={{ ease: "easeIn", duration: motionDuration }}
              alt=""
            ></MotionImage>
            <MotionImage
              src="./images/carousel/Layer_3.png"
              width="50%"
              initial={{
                left: counter === 20 ? "26vw" : "2vw",
                top: counter === 20 ? "100px" : "12vw",
              }}
              animate={{ left: "2vw", top: "12vw" }}
              transition={{ duration: motionDuration }}
              alt=""
            ></MotionImage>
            <MotionImage
              src="./images/carousel/Layer_2.png"
              width="50%"
              initial={{
                left: counter === 20 ? "26vw" : "50vw",
                top: counter === 20 ? "100px" : "12vw",
              }}
              animate={{ left: "50vw", top: "12vw" }}
              transition={{ duration: motionDuration }}
              alt=""
            ></MotionImage>
            <MotionImage
              src="./images/carousel/Layer_1.png"
              width="50%"
              animate={{ rotate: 0 }}
              transition={{ duration: motionDuration }}
              alt=""
            ></MotionImage>
          </Box>
          <Box
            position="absolute"
            width="100%"
            top={isMaxThan1024 ? '30.01vw' : "35.01vw"}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <MotionBox
              width="400px"
              position="relative"
              initial={{ left: counter === 20 ? "-100%" : "7.8125vw" }}
              animate={{ left: "7.8125vw" }}
              transition={{ duration: motionDuration }}
            >
              <Box
                fontSize="42px"
                color="white"
                lineHeight="68px"
                fontWeight="bold"
              >
                GRAB A KEY AND
              </Box>
              <Box
                fontSize="76px"
                color="white"
                lineHeight="68px"
                fontWeight="bold"
              >
                UNLOCK
              </Box>
              <Box
                fontSize="51px"
                color="white"
                lineHeight="68px"
                fontWeight="bold"
              >
                THE FUTURE
              </Box>
            </MotionBox>
            <MotionBox
              width="514px"
              position="relative"
              initial={{ left: counter === 20 ? "-100%" : "0" }}
              animate={{ left: "0" }}
              transition={{ duration: motionDuration }}
            >
              <Box fontSize="20px" lineHeight="25px" color="white">
                We’re the new house of ideas and the Founders Keys deepen your
                relationship to all of our initiatives. Get special access to
                our video content, our University, our narrative content,
                everything we’re doing in Web3, and the upcoming game world
                Project Kyzen that ties our entire creative universe together in
                one empowering place.
              </Box>
              <Box
                onClick={() => {
                  scrollToRefOpenSea.current.scrollIntoView({
                    behavior: "smooth",
                    block: "start",
                  });
                }}
                className={styles.learnMore}
              >
                LEARN MORE
              </Box>
            </MotionBox>
          </Box>
          <img src="./images/carousel/Dust.png" alt=""></img>
        </Box>
      )}
      {index === 2 && (
        <MotionBox
          bg='transparent url("/images/carousel-3-bg.png") 0% 0% no-repeat padding-box'
          bgSize="cover"
          position="relative"
          width="100%"
        >
          <MotionImage
            src="./images/carousel-3.png"
            alt=""
            width="100vw"
            position="relative"
            bottom="-100px"
            initial={{ y: counter === 20 ? "0" : "-100px" }}
            animate={{ y: "-100px" }}
            transition={{ ease: "easeIn", duration: motionDuration }}
          />
          <Box color="#000" position="absolute" left="70px" top="12vw">
            <Text fontSize="75px" lineHeight="60px" fontWeight="bold">
              PROJECT
            </Text>
            <Text fontSize="108px" lineHeight="92px" fontWeight="bold">
              KYZEN
            </Text>
            <Text fontSize="41px" lineHeight="42px" fontWeight="700">
              A NEW FORM OF
            </Text>
            <Text fontSize="41px" lineHeight="42px" fontWeight="700">
              ENTERTAINMENT
            </Text>
          </Box>
          <MotionBox
            width="364px"
            bottom="0"
            initial={{ y: counter === 20 ? "0" : isMaxThan1024 ? "0" : '-200px' }}
            animate={{ y: isMaxThan1024 ? "0" : '-200px' }}
            position="absolute"
            right="70px"
            transition={{ duration: motionDuration }}
            className={styles.pkTextBox}
          >
            <Box fontSize="20px" lineHeight="24px" color="#000">
              Using NFTs as “signaling molecules” that tailor in-game
              experiences based on what a player holds in their wallet, Impact
              Theory is proving NFTs are a revolution in entertainment.
            </Box>
            <Box
              onClick={() => {
                window.open("https://projectkyzen.io");
              }}
              className={styles.learnMore}
            >
              LEARN MORE
            </Box>
          </MotionBox>
        </MotionBox>
      )}

      <Box className={styles.carouselIndex}>
        <motion.img
          whileHover={{ rotate: 90 }}
          src={`./images/hexagon_home_${index === 0 ? "on" : "off"}.png`}
          onClick={() => {
            setCounter(0);
            if (timerID) {
              clearTimeout(timerID);
              setTimerID(null);
            }
            setIndex(0);
          }}
          alt=""
        />
        <motion.img
          whileHover={{ rotate: 90 }}
          src={`./images/hexagon_fk_${index === 1 ? "on" : "off"}.png`}
          onClick={() => {
            setIndex(1);
            setCounter(20);
          }}
          alt=""
        />
        <motion.img
          whileHover={{ rotate: 90 }}
          src={`./images/hexagon_kyzen_${index === 2 ? "on" : "off"}.png`}
          onClick={() => {
            setIndex(2);
            setCounter(20);
          }}
          alt=""
        />
        <motion.img
          src={`./images/carousel/trumpet.svg`}
          onClick={() => {
            if (ref) {
              ref.current.muted = !ref.current.muted;
            }
          }}
          alt=""
        />
      </Box>
    </Box>
  );
}
