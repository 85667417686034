import styles from "/styles/Home.module.css";
import {
  Text,
  Box,
  Grid,
  GridItem,
  Stack,
  Image,
  Center,
} from "@chakra-ui/react";
import { useInViewport } from "react-in-viewport";
import { useRef } from "react";
import { motion } from "framer-motion";

export default function HouseOfIdeasSection() {
  const ref = useRef(null);
  const { inViewport } = useInViewport(
    ref,
    { rootMargin: "-50px" },
    { disconnectOnLeave: false },
    {}
  );

  const motionDuration = 1;

  const MotionImage = motion(Image);
  const MotionDiv = motion(Box);
  const renderWidth = width => {
    return (width / 1920) * 100 + "vw";
  };

  const transitionSettings = {
    duration: motionDuration,
    delay: 1.0,
  };

  return (
    <Box w="100%" color="#000000">
      <Box ref={ref} marginTop="100px">
        {/* <ScaleFade initialScale={0.9} in={inViewport}> */}
        <Grid templateColumns="repeat(5, 1fr)" gap={4}>
          <GridItem rowSpan={2} colSpan={5} bg="white">
            <Center>
              <Stack direction="row" spacing="2px">
                <MotionImage
                  src="./images/story-neon-future.png"
                  alt="Neon Future"
                  initial={{ postion: "relative", top: "-49px" }}
                  animate={{ postion: "relative", top: "0" }}
                  transition={transitionSettings}
                  position="relative"
                  top="-49px"
                />
                <Image
                  src="./images/story-wish-academy.png"
                  alt="Wish Academy"
                  position="relative"
                  top="0"
                />
                <MotionImage
                  src="./images/story-cutting-black.png"
                  alt="Cutting Black"
                  position="relative"
                  top="-95px"
                  initial={{ postion: "relative", top: "-95px" }}
                  animate={{ postion: "relative", top: "0" }}
                  transition={transitionSettings}
                />
                <MotionImage
                  objectFit="cover"
                  src="./images/story-coyotes.png"
                  alt="Coyotes of the Air Gap"
                  position="relative"
                  top="-33px"
                  initial={{ postion: "relative", top: "-33px" }}
                  animate={{ postion: "relative", top: "0" }}
                  transition={transitionSettings}
                />
                <MotionImage
                  objectFit="cover"
                  src="./images/story-all-systems.png"
                  alt="All Systems Go"
                  position="relative"
                  top="-66px"
                  initial={{ postion: "relative", top: "-66px" }}
                  animate={{ postion: "relative", top: "0" }}
                  transition={transitionSettings}
                />
                <MotionImage
                  objectFit="cover"
                  src="./images/story-burnland.png"
                  alt="Burnland"
                  position="relative"
                  top="-95px"
                  initial={{ postion: "relative", top: "-95px" }}
                  animate={{ postion: "relative", top: "0" }}
                  transition={transitionSettings}
                />
                <MotionImage
                  objectFit="cover"
                  src="./images/story-hexagon.png"
                  alt="Hexagon"
                  position="relative"
                  top="-20px"
                  initial={{ postion: "relative", top: "-20px" }}
                  animate={{ postion: "relative", top: "0" }}
                  transition={transitionSettings}
                />
              </Stack>
            </Center>
          </GridItem>

          <GridItem
            rowSpan={2}
            colSpan={5}
            bg="white"
            paddingBottom="200px"
            width={{ base: '100%', xl: '1380px' }}
            margin="0 auto"
          >
            <Center>
              <Grid templateColumns="repeat(7, 1fr)" gap={4}>
                <GridItem colSpan={3}>
                  <MotionDiv
                    position="relative"
                    top="50px"
                    initial={{
                      postion: "relative",
                      bottom: "0",
                      lineHeight: "45px",
                    }}
                    animate={{
                      postion: "relative",
                      bottom: "0",
                      lineHeight: "61px",
                    }}
                    transition={transitionSettings}
                  >
                    <Box fontSize="3rem" fontWeight="bold">
                      WE ARE THE NEW
                    </Box>
                    <Box fontSize="3rem" fontWeight="bold">
                      HOUSE OF IDEAS
                    </Box>
                  </MotionDiv>
                </GridItem>

                <GridItem colSpan={2}>
                  <span fontSize="20px" fontWeight="medium">
                    Our mission is to introduce people to empowering ideas at
                    scale through story. In addition to Project Kyzen and Merry
                    Modz, we currently have an entire slate of stories in
                    development. There are several different ways that our
                    projects will take to come to life, but the most common path
                    will be starting as a comic book.
                  </span>
                </GridItem>
                <GridItem colSpan={2}>
                  <span fontSize="20px" fontWeight="medium">
                    This will allow us to explore the world and story as we
                    build an audience. Two of our projects have already found
                    success in this way (Neon Future and Hexagon). Both projects
                    spent their entire run in the top 10 of their respective
                    categories on Webtoon - garnering more than a million views
                    collectively.
                  </span>
                </GridItem>
              </Grid>
            </Center>
          </GridItem>
        </Grid>

        {/* </ScaleFade> */}
      </Box>
    </Box>
  );
}
